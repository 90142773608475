import React from 'react'
import InnerHeader from '../../components/layout/public/components/InnerHeader'
import SecretariatBoardCard from '../../components/shared/card/SecretariatBoardCard'
import CardPresident from "../../components/shared/card/CardPresident"
import { getBoardSecretariat } from '../../components/slices/boardSecretariat'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useMemo } from 'react'
import { motion } from 'framer-motion'
export default function Board() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBoardSecretariat({type:1})).then(res => {
      return(res.payload)
    })
  }, [dispatch]);
  const board = useSelector((state) => state.boardSecretariat.boardSecretariat)

  const onePer = useMemo(
    () => board.find(onePerson => onePerson.main ===1)
  ,[board])
  const otherPer = useMemo(
    () => board.filter(otherPerson => otherPerson.main ===0)
  ,[board])

  const container = {
    visible:{
      transition:{
        delayChildren:0.5,
        staggerChildren:0.4
      }
    }
  }
  
  return (
    <div>
    <InnerHeader
      title={'Board'}
      text={
        'The board of NAM Youth Organization is the governing body representing and overseeing the Organization’s strategic development at the international level.'
      }
      img={require('../../assets/images/secretariat-pic.png')}
    />
    <div className="mx-[50px] py-[50px]">
      {onePer &&<CardPresident img={`https://namyouth.org/storage/${onePer.image}`} name={onePer.title} about={onePer.description}/>}
      <motion.div
      variants={container}
      initial="hidden"
        animate="visible"
       className='mt-[100px] grid grid-cols-4 gap-4'>
        {otherPer && otherPer.map(otherPersons => (
          <SecretariatBoardCard 
          image={`https://namyouth.org/storage/${otherPersons.image}`}
          fullName={otherPersons.title}
          description={otherPersons.description}
          unique={otherPersons.id}
          key={otherPersons.id}
          facebook={otherPersons.facebook}
          instagram={otherPersons.instagram}
          />
        ))}

      </motion.div>
    </div>
  </div>
  )
}
