import React, { useEffect, useState } from 'react'
import MiniHeader from '../../components/layout/public/components/MiniHeader'
import Bio from '../../components/shared/bio/Bio'
import ProjectsCard from '../../components/shared/card/ProjectsCard'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { getCountries } from '../../components/slices/auth'
import { useDispatch, useSelector } from 'react-redux'
import { useMemo } from 'react'
import { Oval } from 'react-loader-spinner'
import MainProjectsCard from '../../components/shared/card/MainProjectsCard'
import { getProjects } from '../../components/slices/projects'

export default function BoardDetail() {
  const [boardDetailPerson, setBoardDetailPerson] = useState({})
  const [countries, setCountries] = useState([])
  const { id } = useParams()
  const [next, setNext] = useState(2);
  const handleMoreImage = () => {
    setNext(next + 2);
  };
  const dispatch = useDispatch()
  const projects = useSelector((state) => state.projects.projects)
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_DEV_API_URL}api/boardSecretariat/` + id)
      .then((res) => {
        setBoardDetailPerson(res.data.data.boardSecretariat)
      })
  }, [id])
  useEffect(() => {
    dispatch(getProjects()).then(res => {
      return (res.payload)
    })
  },[dispatch])
  useEffect(() => {
    dispatch(getCountries()).then((res) => {
      setCountries(res?.payload?.countries?.data?.countries)
    })
  }, [dispatch])


  const oneCountry = useMemo(
    () =>
      countries.find((country) => country.id === boardDetailPerson.country_id),
    [countries, boardDetailPerson.country_id],
  )



  return (
    <>
      {boardDetailPerson && oneCountry ? (
        <>
          <MiniHeader
            bgImage={require('../../assets/images/miniheader.png')}
            name={boardDetailPerson.title}
            duty={boardDetailPerson.duty}
            personImg={`${process.env.REACT_APP_DEV_API_URL}/storage/${boardDetailPerson.image}`}
            country={oneCountry.name}
            facebook={boardDetailPerson.facebook}
            instagram={boardDetailPerson.instagram}
          />
          <Bio bioText={boardDetailPerson.description} />
          <div className="px-[50px] mt-[100px]">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-10 pb-[100px]">
              {projects &&
                projects
                  ?.slice(0, next)
                  ?.map((project) => (
                    <MainProjectsCard
                      cardId={project.id}
                      image={`${process.env.REACT_APP_DEV_API_URL}storage/${project.cover}`}
                      text={project.title}
                    />
                  ))}
            </div>
            {next < projects?.length && (
              <div className="flex justify-center pb-[137px]">
                <button
                  className="mt-2 bg-[#2A538A] text-[white] py-[25px] px-[52px] font-bold hover:bg-[white] hover:text-[#2A538A] border border-[#2A538A] ease-in duration-100"
                  onClick={handleMoreImage}
                >
                  Load more
                </button>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="h-[100vh] bg-[#E9EDF6] flex justify-center items-center text-white text-[44px]">
          <Oval
            height={80}
            width={80}
            color="#2A538A"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#2A538A"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}
    </>
  )
}
