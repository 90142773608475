import React, { useState, useEffect } from 'react'
import AboutImg from '../../../assets/images/about-1.png'
import { getConcepts } from '../../slices/auth'
// import { getCountries } from "../../slices/auth";
import { useDispatch, useSelector } from 'react-redux'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const ConceptItem = ({ className }) => {
  const [concept, setConcept] = useState([])
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getConcepts()).then((res) => {
      setConcept(res.payload.concepts.data.concepts)
      console.log(res.payload.concepts.data.concepts)
    })
  }, [dispatch])

  return (
    <div className={`flex ${className} flex-col item-nth`}>
      {concept?.map((item) => (
        <div className="flex gap-x-[32px] max-[912px]:flex-col" key={item.id}>
          <LazyLoadImage
          width={300}
          height={300}
            className="object-cover max-[912px]:w-[100%] max-[912px]:h-[100%] w-[652px] h-[545px]"
            src={`https://namyouth.org/storage/${item.image}`}
            
          />
          {/* <img src={`https://namyouth.org/storage/${item.image}`} alt="" className='object-cover max-[912px]:w-[100%] max-[912px]:h-[100%] w-[652px] h-[545px]' /> */}
          <div>
            <h4 className="font-bold text-[42px] leading-[43px] pb-[50px] pt-[60px] max-[912px]:pt-[30px] max-[912px]:leading-[32px] max-[912px]:text-[26px]">
              {item.title}
            </h4>
            <p className="text-[16px] leading-[28px]">{item.description}</p>
          </div>
        </div>
      ))}
    </div>
  )
}

export default ConceptItem
