import React from 'react'
import { NavLink, Link, Form } from 'react-router-dom'
import { BsUpload } from 'react-icons/bs'
import { useLocation } from 'react-router-dom'
import { useRef, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setImage } from '../../../slices/addImg'
import { setImageEdit } from '../../../slices/addImgEdit'
import { getMemberProfile } from '../../../slices/auth'
import { useParams } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const MyCabinetHeader = (props) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const [avatar, setAvatar] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')

  const { id } = useParams()
  const token = JSON.parse(localStorage.getItem('token'))

  useEffect(() => {
    dispatch(getMemberProfile({ id: id, token: token })).then((response) => {
      setFirstName(response?.payload?.member?.data?.member?.first_name)
      setLastName(response?.payload?.member?.data?.member?.last_name)
      setAvatar(response?.payload?.member?.data?.member?.avatar)
    })
  }, [dispatch])

  const myCabinetNav = [
    { to: `userView`, name: 'View' },
    { to: `userRolesHistory`, name: 'Role History' },
  ]
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${require('../../../../assets/images/my-cabinet-bg.png')})`,
        }}
        className="transition-all duration-500 h-[500px] w-[100%] bg-no-repeat bg-cover relative"
      >
        <div className="bg-[#0B2447] bg-opacity-50 h-[100%]">
          <div className="flex justify-center">
          <div className="absolute left-[50%] top-[55%] translate-x-[-50%] translate-y-[-50%] rounded-full w-[200px] h-[200px] md:w-[260px] md:h-[260px]">
              <div className="relative">
                <img
                  className="opacity-[50] object-cover w-[200px] h-[200px] md:w-[260px] md:h-[260px] border-[#FFFFFF] border-[8px] border-solid rounded-full"
                  src={`https://namyouth.org/storage/${avatar}`}
                  alt=""
                />
                <div className="bg-[rgba(11,36,71,.5)] absolute w-[100%] h-[100%] top-0 rounded-full"></div>
              </div>
            </div>
          </div>

          <div className="text-center absolute bottom-[5%] left-[50%] translate-x-[-50%] text-[#FFFFFF] font-bold text-[24px] leading-7">
          <span className="text-[21px] md:text-[24px] ">
              {firstName} {lastName}
            </span>
          </div>
        </div>
      </div>
      <div className="pt-[33px] px-[50px] ">
        <ul className="text-[#202021]">
          {myCabinetNav.map((link) => (
            <NavLink
              to={link.to}
              key={link.to}
              className={({ isActive }) =>
                isActive
                  ? 'activeLink text-[18px] mr-[30px] pb-[10px]'
                  : 'text-[18px] mr-[30px]'
              }
            >
              {link.name}
            </NavLink>
          ))}
        </ul>
        <hr className="mt-[15px] mb-[30px]" />
      </div>
    </>
  )
}

export default MyCabinetHeader
