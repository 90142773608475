import React from 'react'
import { Link } from 'react-router-dom'
import LazyImage from '../../lazyLoad/LazyImage'
const UserCard = (follower) => {
  const {
    login,
    first_name,
    last_name,
    avatar,
    nationality,
    id,
    roles,
  } = follower
  const url = 'https://namyouth.org/storage/'

  return (
    <Link to={`/user/${id}/userView`}>
      <div className="w-[100%] mt-[20px] md:w-[310px] bg-[#F0F0F0] mx-auto hover:-translate-y-[10px] ease-in duration-300">
        <LazyImage
          height={'282px'}
          width={'100%'}
          smHeight={'250px'}
          smWidth={'100%'}
          // className="w-[100%] h-[282px] object-cover"
          src={url + avatar}
        />
        <h4 className=" font-bold text-[24px] pb-[20px] text-[#202021] pt-[16px] px-[14px]">
          {first_name + ' ' + last_name}
        </h4>
        <div className="flex justify-between pl-[14px] pb-[19px] pr-[8px]">
          <Link to={`/user/${id}/userRolesHistory`}>view role history</Link>
          <span>{nationality}</span>
        </div>
      </div>
    </Link>
  )
}

export default UserCard
