import React from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import './style.css'
import { Navigation } from 'swiper'

export default function NewsSlider({ images }) {
  return (
    <>
      <Swiper
        slidesPerView={1}
        navigation={{
          prevEl: '.swiper-button-prev',
          nextEl: '.swiper-button-next',
        }}
        
        modules={[Navigation]}
      >
        
        {images.map(image => (
          <SwiperSlide>
          <div className="w-[80%] mx-auto">
            <img
              src={`https://namyouth.org/storage/${image}`}
              alt=""
              className="w-[100%] object-cover h-[300px] md:h-[600px]"
            />
          </div>
        </SwiperSlide>
        ))}
        <div className="swiper-button-prev"></div>
        <div className="swiper-button-next"></div>
      </Swiper>
    </>
  )
}
