import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useDispatch, useSelector } from 'react-redux'
import { member } from '../../components/slices/auth'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import SearchInput from '../../components/shared/search/SearchInput'
import paginate from '../../components/shared/pagination/Paginate'
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md'
import Follower from '../../components/shared/card/UserCard'

function createData(image, member, role, update, join) {
  return { image, member, role, update, join }
}

export default function CountryNameMembers() {
  const [page, setPage] = useState(0)
  const [allMembers, setAllMembers] = useState([])

  const { id } = useParams()
  const dispatch = useDispatch()
  const [membersList, setMembersList] = useState([])
  // const [roles, setRoles] = useState([]);
  const [firstNameN, setFirstNameN] = useState('')

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  useEffect(() => {
    if (loading) return
    setAllMembers(data[page])
  }, [loading, page, firstNameN])

  useEffect(() => {
    dispatch(member({ chapterMembers: id })).then((res) => {
      setMembersList(res?.payload?.member)
      if (res) {
        setData(paginate(res?.payload?.member))
        setLoading(false)
        setPage(0)
      } else {
        setPage(0)
      }
    })
  }, [dispatch])

  const handlePage = (index) => {
    setPage(index)
  }
  const nextPage = () => {
    if (page === data.length - 1) {
      setPage(0)
    } else {
      setPage(page + 1)
    }
  }
  const prevPage = () => {
    if (page === 0) {
      setPage(data.length - 1)
    } else {
      setPage(page - 1)
    }
  }
  const searchClick = (e) => {
    e.preventDefault()
    setLoading(true)
    setFirstNameN(e.target.value)

    if (e.target.value.trim() == '') {
      setData(membersList)
    } else {
      dispatch(member({ chapterMembers: id, firstNameN }))
        .unwrap()
        .then((res) => {
          if (res) {
            setData(paginate(res.member))
            setLoading(false)
            setPage(0)
          } else {
            setPage(0)
          }
        })
        .catch((error) => {
          // setFirstNameN("")
          console.log(error)
        })
    }
  }

  const searchDynamic = (e) => {
    if (e.target.value.trim() == '') {
      setAllMembers(membersList)
    } else {
      const filterData = [
        ...membersList.filter((member) =>
          member.first_name
            .trim()
            .toLowerCase()
            .includes(e.target.value.trim().toLowerCase()),
        ),
      ]

      setAllMembers(filterData)
    }
  }

  const convertDate = (time) => {
    const dateString = time
    const date = new Date(dateString)
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return date.toLocaleDateString('en-US', options).split('/').join('-');
  }

  return (
    <div className="px-[52px]">
      <h4 className="text-[#202021] text-[44px] pb-[16px] pt-[63px]">
        {membersList?.length} Members{' '}
      </h4>
      <div className="flex border border-black mb-[70px]">
        <SearchInput
          placeholder="Find a member"
          // value={firstNameN}
          SearchClick={searchClick}
          handleSearch={(e) => searchDynamic(e)}
        />
      </div>
      <TableContainer component={Paper} className="">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                align="left"
                className="font-bold text-[14px] text-[#202021]"
              ></TableCell>
              <TableCell
                align="left"
                className="font-bold text-[14px] text-[#202021]"
              >
                Member
              </TableCell>
              <TableCell
                align="left"
                className="font-bold text-[14px] text-[#202021]"
              >
                Roles
              </TableCell>
              <TableCell
                align="left"
                className="font-bold text-[14px] text-[#202021]"
              >
                Updated
              </TableCell>
              <TableCell
                align="left"
                className="font-bold text-[14px] text-[#202021]"
              >
                Joined
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* <TableCell align="left"><img className='w-[121px] h-[121px] rounded-full object-cover ' src={row.image} alt="" /></TableCell>
                <TableCell align="left" className='text-[14px] text-[#202021]'>{row.member}</TableCell>
                <TableCell align="left" className='text-[14px] text-[#202021]'>{row.role}</TableCell>
                <TableCell align="left" className='text-[14px] text-[#202021]'>{row.update}</TableCell>
                <TableCell align="left" className='text-[14px] text-[#202021]'>{row.join}</TableCell> */}
            {allMembers?.map((member) => (
              <TableRow key={member.id}>
                <TableCell align="left">
                  <img
                    className="w-[70px] h-[70px]  lg:w-[121px] lg:h-[121px] rounded-full object-cover "
                    src={`https://namyouth.org/storage/${member?.avatar}`}
                    alt=""
                  />
                </TableCell>
                <TableCell align="left" className="text-[14px] text-[#202021]">
                  {member?.first_name}
                  {member?.last_name}
                </TableCell>
                <TableCell align="left" className="text-[14px] text-[#202021]">
                  {member.roles != 0
                    ? member.roles.map((x) => (
                        <span className="block">{x.name}</span>
                      ))
                    : 'not yet'}
                </TableCell>
                <TableCell align="left" className="text-[14px] text-[#202021]">
                  {convertDate(member?.updated_at)}
                </TableCell>
                <TableCell align="left" className="text-[14px] text-[#202021]">
                  {convertDate(member?.email_verified_at)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <div className='flex flex-wrap'>
       {allMembers ? allMembers.map((follower) => {
            return <Follower key={follower.id} {...follower}  />;
          }) : <span className="mt-[-170px] text-[red] text-[22px]">no information found</span>}
       </div> */}

      <div className="btn-container flex justify-center pt-[95px] pb-[254px]">
        {allMembers ? (
          <button
            className="btn next-btn bg-[#FFFFFF] border-[1px] border-[#E9E9E9] py-[4px] px-[12px]"
            onClick={() => prevPage()}
          >
            {!loading ? <MdKeyboardArrowLeft /> : null}
          </button>
        ) : (
          ''
        )}

        {loading
          ? null
          : data.map((item, index) => {
              return (
                <button
                  key={index}
                  className={`page-btn border-[1px] border-[#E9E9E9] py-[4px] px-[12px] ${
                    index === page
                      ? 'active-btn bg-[#2A538A] text-[#FFFFFF] border-[1px] border-[#E9E9E9] py-[4px] px-[12px]'
                      : null
                  }`}
                  onClick={() => {
                    handlePage(index)
                  }}
                >
                  {index + 1}
                </button>
              )
            })}
        {allMembers ? (
          <button
            className="btn next-btn bg-[#FFFFFF] border-[1px] border-[#E9E9E9] py-[4px] px-[12px]"
            onClick={() => nextPage()}
          >
            {!loading ? <MdKeyboardArrowRight /> : null}
          </button>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}
