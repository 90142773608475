import React, { useEffect, useState } from 'react'
import BlogArticlesCard from '../../components/shared/card/BlogArticlesCard'
import { FaFacebookF, FaLinkedinIn, FaTwitter } from 'react-icons/fa'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { MdKeyboardArrowLeft } from 'react-icons/md'
import NewsHeader from '../../components/layout/public/components/InnerHeader'
import getArticles from '../../components/services/article.service'
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share'
import { Oval } from 'react-loader-spinner'

export default function ArticleDetail() {
  const { id } = useParams()
  const [articleDetail, setArticleDetail] = useState({})
  const [moreArticles, setMoreArticles] = useState([])
  const [next, setNext] = useState(3)
  const location = useLocation()
  const locationParse = location.pathname.split('/')
  const navigate=  useNavigate()
  const handleMoreItem = () => {
    setNext(next + 3)
  }
  useEffect(() => {
    getArticles(id).then((res) => {
      setArticleDetail(res.data.article)
    })
  }, [id])

  useEffect(() => {
    getArticles().then((res) => {
      const data = res.data.articles.filter(
        (blog) => blog.id !== articleDetail.id,
      )
      setMoreArticles(data)
    })
  }, [articleDetail])

  return (
    <>
    {!articleDetail.cover_image ?   <div className="h-[100vh] bg-[#E9EDF6] flex justify-center items-center text-white text-[44px]">
          <Oval
            height={80}
            width={80}
            color="#2A538A"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#2A538A"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div> : <>
        <NewsHeader
        img={require('../../assets/images/opp-bg-img.png')}
        title={'Article'}
        text={
          'incididunt ut ero ero labore et dolore magna aliqua. Ut enim ad minim veniam, quis incididunt ut ero labore et dolore magna aliqua. Ut enim ad minim veniam, quis '
        }
      />
      <div className="px-[50px]">
        <div className="flex">
        <div className="mt-[103px]">
            <button
              onClick={() => navigate(-1)}
              className="flex items-center text-[#202021] text-[14px] font-bold"
            >
              {' '}
              <MdKeyboardArrowLeft className="text-[#202021] text-[18px] font-bold" />{' '}
              Back
            </button>
            <div className="mt-[74px] ">
              <div className="text-center">
                <h1 className="text-[#202021] text-[14px] font-bold">Share</h1>
              </div>
              <div className="mt-[23px] flex flex-col">
                <FacebookShareButton
                  url={`${process.env.REACT_APP_DEV_API_URL}${locationParse[1]}/${id}`}
                >
                  <FaFacebookF className="text-[20px] mb-[28px]" />
                </FacebookShareButton>
                <LinkedinShareButton
                  url={`${process.env.REACT_APP_DEV_API_URL}${locationParse[1]}/${id}`}
                >
                  <FaLinkedinIn className="text-[20px] mb-[28px]" />
                </LinkedinShareButton>
                <TwitterShareButton
                  url={`${process.env.REACT_APP_DEV_API_URL}${locationParse[1]}/${id}`}
                >
                  <FaTwitter className="text-[20px] mb-[28px]" />
                </TwitterShareButton>
              </div>
            </div>
          </div>
          <div className="w-[75%] mx-auto">
            <div className="py-[5px]">
              <h1 className="text-[#202021] text-[38px] font-normal">
                {articleDetail?.title}
              </h1>
              <span className="text-[16px]"></span>
            </div>
            <div className="block lg:flex justify-between items-center py-[20px]">
              <div className="w-[100%]">
                {/* <div
                className="mr-[32px]"
                dangerouslySetInnerHTML={{
                  __html: detailData.description,
                }}
              /> */}
                <div className="flex items-center">
                  <div className="h-[32px] w-[32px] border-full rounded-full bg-[#202021] mr-[15px]">
                    <img className='object-cover rounded-full' src={`${process.env.REACT_APP_DEV_API_URL}storage/${articleDetail?.member?.avatar}`} alt="" />
                  </div>
                  <div>
                    <h1 className="text-neutral-800 text-[12px] font-normal mr-[15px]">
                      {articleDetail.member?.first_name}{' '}
                      {articleDetail?.member?.last_name}
                    </h1>
                  </div>
                </div>
                <div className="mr-[32px] mt-[20px]">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: articleDetail.description,
                    }}
                  ></div>
                </div>

                {/* <div className="py-[26px]">
                <span>
                {detailData.address} {formattedDateNew}
                </span>
              </div> */}

                {/* <div className="flex justify-center mt-[33px]">
                  <button
                    onClick={() => navigate(`/${detailData.apply_link}`)}
                    className="font-bold text-[18px] px-[70px] py-[15px] bg-[black] text-white hover:bg-white hover:text-black border border-[black]"
                  >
                    Apply
                  </button>
                </div> */}
              </div>
              {/* <div className="w-[100%] lg:w-[50%] mt-[50px] lg:mt-[0]">
                <img
                  className="w-[100%] h-[100%] object-cover"
                  src={`https://namyouth.org/storage/${detailData.image}`}
                  alt=""
                />
              </div> */}
            </div>
            {/* <div>
              <div className="py-[10px] border-b border-[#F0F0F0]">
                <h1 className="font-bold text-[24px]">{detailData.inner_title}</h1>
              </div>
              <div
                className="py-[38px]"
                dangerouslySetInnerHTML={{
                  __html: detailData.inner_description,
                }}
              />
            </div> */}
            {/* <div className="py-[85px]">
              <div>
                <h1 className="font-bold text-[36px]">
                  Most popular opportunities
                </h1>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 mt-[54px]">
                {doubleSpecialCards &&
                  doubleSpecialCards
                    ?.slice(0, next)
                    ?.map((opportunity) => (
                      <OpportunitiesCard
                        func={() => window.scrollTo({ top: 0 })}
                        to={`/opportunities/exchangeprograms/${opportunity.id}`}
                        key={opportunity.id}
                        img={opportunity.image}
                        title={opportunity.title}
                        address={opportunity.address}
                        deadLine={opportunity.deadline}
                      />
                    ))}
    
                {doubleSpecialCards.length === 0 && (
                  <div className="text-[25px] font-bold py-[25px]">No data</div>
                )}
              </div>
              {next < doubleSpecialCards?.length &&
              doubleSpecialCards.length > 3 ? (
                <div className="flex justify-center pb-[137px]">
                  <button
                    className="mt-4 bg-[#202021] text-[white] py-[25px] px-[52px] font-bold hover:bg-[white] hover:text-[black] border border-[black] ease-in duration-100"
                    onClick={handleMoreImage}
                  >
                    Load more
                  </button>
                </div>
              ) : (
                ''
              )}
            </div> */}
          </div>
        </div>

        {/* More blog posts */}
        <div className="py-[6rem]">
          <div>
            {moreArticles.length > 0 &&  <h1 className="text-neutral-800 text-[44px] font-normal">
              More Article Posts
            </h1>}
           
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 mt-[54px]">
            {moreArticles &&
              moreArticles
                .slice(0, next)
                .map((blog) => (
                  <BlogArticlesCard
                    id={blog.id}
                    key={blog.id}
                    coverImage={blog.cover_image}
                    description={blog.description}
                    title={blog.title}
                    createdTime={blog.created_at}
                    memberName={blog.member.first_name}
                    memberLastName={blog.member.last_name}
                    memberImage={blog.member.avatar}
                  />
                ))}
          </div>
          {next < moreArticles?.length && moreArticles.length > 3 ? (
              <div className="flex justify-center pb-[137px]">
                <button
                  className="mt-4 bg-[#2A538A] text-[white] py-[25px] px-[52px] font-bold hover:bg-[white] hover:text-[#2A538A] border border-[#2A538A] ease-in duration-100"
                  onClick={handleMoreItem}
                >
                  Load more
                </button>
              </div>
            ) : (
              ''
            )}
        </div>
      </div>
        </>}
      
    </>
  )
}
