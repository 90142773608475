import React from 'react'
import { useLocation } from 'react-router-dom'
export default function InnerHeader({ title, text,img }) {
  const location = useLocation()
  const locationParse = location.pathname.split('/')

  return (
    <div
      style={{
        backgroundImage: `url(${
          location.pathname === '/about'
            ? require('../../.././../assets/images/Non-AlignedMovement.jpeg')
            : locationParse[1] === 'news'
            ? require('../../.././../assets/images/headerNews.png')
            : location.pathname === '/whoweare'
            ? require('../../.././../assets/images/whoweare.jpg')
            : location.pathname === '/timeline'
            ? require('../../.././../assets/images/google2.png')
            : locationParse[1] === 'opportunities'
            ? require('../../.././../assets/images/opp-bg-img.png')
            : ''
           
         || img } ) `,
      }}
      className="h-[85vh] w-[100%] relative bg-no-repeat bg-center	bg-cover"
    >
              <div className='bg-sky-950 bg-opacity-50 h-[100%]'>
      <div className="absolute bottom-0 pl-[15px] lg:pl-[50px] w-[93%] 2xl:w-[40%] pr-[50px] 2xl:pr-[0] pb-[56px]">
        <div>
          <h1 className="text-white font-bold text-[88px] max-[912px]:text-[44px] leading-[50px] xl:leading-[106px]">
            {location.pathname === '/about'
              ? 'Non-Aligned Movement'
              : locationParse[1] === 'news'
              ? 'News'
              : location.pathname === '/whoweare'
              ? 'Who we are?'
              : locationParse[2] === 'job'
              ? 'Job'
              : locationParse[2] === 'internships'
              ? 'Internships'
              : locationParse[2] === 'volunteering'
              ? 'Volunteering'
              : locationParse[2] === 'exchangeprograms'
              ? 'Exchange Programs'
              : locationParse[2] === 'scholarships'
              ? 'Scholarships'
              : locationParse[1] === 'opportunities'
              ? 'Opportunities'
              : locationParse[3] === 'volunteering'
              ? 'volunteering'
              
              : ''}
            {title}
          </h1>
        </div>
        <div className="mt-[17px]">
          <p className="text-white text-[18px] font-normal text-justify">
            {location.pathname === '/about'
              ? 'Since its establishment in 1961, the Non-Aligned Movement has played a critical role as a driving force of peace, multilateralism, and international solidarity. It has served as a neutral medium for resolving global pressing challenges by promoting dialogue and cooperation.'
              : locationParse[1] === 'news'
              ? 'Stay informed about news about NAM Youth Organization'
              : location.pathname === '/whoweare'
              ? 'NAM Youth Organization offers a chance for the youths of NAM Member States to exchange ideas, visions, and perspectives on current challenges they face in ensuring sustainable progress and offer solutions on how these difficulties could be overcome through concerted and adequate responses of their States.'
           
              : locationParse[2] === 'internships'
              ? 'Interested in youth diplomacy, intercultural dialogue, and sustainable development? Check out our internship opportunities to gain first-hand work experience in the largest international youth organization! '
              : locationParse[2] === 'volunteering'
              ? 'Get involved in NAM Youth Organization’s activities and events as a volunteer by exploring the below opportunities.'
              : locationParse[2] === 'exchangeprograms'
              ? 'Broaden your horizons and discover a world of opportunities through our student exchange programs in partnership with prestigious higher educational institutions.'
              : locationParse[2] === 'scholarships'
              ? 'Explore the list of merit- and need-based scholarship programs we offer to outstanding students.'
              : locationParse[1] === 'opportunities'
              ? 'Join the Secretariat of the NAM Youth Organization through our job openings'
              : ''}
            {text}
         
          </p>
        </div>
      </div>
      </div>
      
    </div>
  )
}
